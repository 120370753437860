import { Tabs, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import DataForm from "./DataForm";
import "../../index.css";
import CredentialsForm from "./CredentialsForm";
import { GlobalContext } from "../../../context/GlobalContext";
import GETObtenerIntegracion from "../../../../helpers/integraciones/GETObtenerIntegracion";

const TabsIntegraciones = ({ drawerIntegration }) => {
  const [integrationData, setIntegrationData] = useState({});
  const [lastSyncDate, setLastSyncDate] = useState(""); // manejo de fecha en state separado para tener la fecha cuando fetchea primero y luego actualizar si se sincroniza

  const { loggedUser } = useContext(GlobalContext);
  useEffect(() => {
    const fetchData = async () => {
      if (drawerIntegration) {
        const { integration } = drawerIntegration;
        const data = await GETObtenerIntegracion(loggedUser, integration);
        if (data) {
          setIntegrationData(data);
          setLastSyncDate(data.mobbexUltimaSincro);
        }
      }
    };

    fetchData();
  }, [loggedUser, drawerIntegration]);

  const items = [
    {
      key: "1",
      label: (
        <Typography.Text strong style={{ margin: 0 }}>
          Datos
        </Typography.Text>
      ),
      children: (
        <DataForm
          drawerIntegration={drawerIntegration}
          integrationData={integrationData}
          lastSyncDate={lastSyncDate}
          setLastSyncDate={setLastSyncDate}
        />
      ),
    },
    {
      key: "2",
      label: (
        <Typography.Text strong style={{ margin: 0 }}>
          Credenciales
        </Typography.Text>
      ),
      children: <CredentialsForm integrationData={integrationData} />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={items} size={"middle"} />;
};

export default TabsIntegraciones;
