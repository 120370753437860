import React from "react";
import { Result, Button } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="notfound-wrapper">
      <Result
        status="warning"
        subTitle="La página solicitada no existe."
        extra={
          <Button
            className="return-button-404"
            type="primary"
            key="console"
            onClick={() => navigate("/integraciones")}
          >
            Volver
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
