/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Divider, Image, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import DrawerIntegracion from "./drawerIntegracion/DrawerIntegracion";
import { GlobalContext } from "../context/GlobalContext";
import MainLayout from "../mainLayout/MainLayout";

// Recibe como props integraciones al igual que MediosPago
const Integraciones = ({ integrations }) => {
  const [activeIntegrations, setActiveIntegrations] = useState([]);
  const [inactiveIntegrations, setInactiveIntegrations] = useState([]);
  const [drawerIntegration, setDrawerIntegration] = useState(null); // Envia como props el status del drawer y la integracion elegida para configurar

  const { selectedIntegration, setSelectedIntegration } =
    useContext(GlobalContext);

  const toggleSelection = (integration) => {
    setSelectedIntegration(integration); // Selecciona globalmente la integración
  };

  const handleTitleClick = (e, integration) => {
    // Detener la propagación del evento para evitar que el click en el título active el click en el Card
    setDrawerIntegration({ visible: true, integration });
    e.stopPropagation();
  };

  useEffect(() => {
    if (integrations && integrations.length > 0) {
      const active = integrations
        .filter((item) => item.activo)
        .sort((a, b) =>
          a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
        );

      const inactive = integrations
        .filter((item) => !item.activo)
        .sort((a, b) =>
          a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0
        );

      if (!selectedIntegration) {
        setSelectedIntegration(active[0]); // Set primer integración como default, sino hay otra
      }
      setActiveIntegrations(active);
      setInactiveIntegrations(inactive);
    } else {
      setActiveIntegrations([]);
      setInactiveIntegrations([]);
    }
  }, [integrations]); // eslint-disable-next-line

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "").replace(".png", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("../../assets/integraciones", false, /\.png$/)
  );

  const renderActiveIntegrations = () => {
    return activeIntegrations.map((item, index) => {
      let imageSrc = images[item.codigo];

      if (imageSrc === undefined) {
        imageSrc = images["DEFAULT"];
      }

      return (
        <Card
          style={{
            width: "140px",
            height: "140px",
            textAlign: "center",
            display: "flex",
            borderColor: "#808080a1",
          }}
          bordered={
            item.idIntegracion === selectedIntegration?.idIntegracion && true
          }
          key={item.idIntegracion}
          className={"card-image"}
          hoverable
          onClick={() => toggleSelection(item)}
        >
          <Image src={imageSrc} preview={false} width={50} height={50} />
          <p>{item.name}</p>
          <Card.Meta
            title={
              <Typography.Text
                className="integration-name"
                strong={false}
                onClick={(e) => handleTitleClick(e, item)}
              >
                {item.nombre}
              </Typography.Text>
            }
          />
        </Card>
      );
    });
  };

  const renderInactiveIntegrations = () => {
    return inactiveIntegrations.map((item, index) => {
      let imageSrc = images[item.codigo];

      if (imageSrc === undefined) {
        imageSrc = images["DEFAULT"];
      }

      return (
        <Card
          style={{
            width: "140px",
            height: "140px",
            textAlign: "center",
            display: "flex",
          }}
          bordered={false}
          key={index}
          className={"disabled-card"}
          hoverable
        >
          <Image src={imageSrc} preview={false} width={50} height={50} />
          <p>{item.name}</p>
          <Card.Meta
            title={
              <Typography.Text strong={false}>{item.nombre}</Typography.Text>
            }
          />
        </Card>
      );
    });
  };

  return (
    <MainLayout>
      <div className="integration-wrapper">
        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Activas
        </Typography.Title>
        <div className="cards-integration">{renderActiveIntegrations()}</div>
        <Divider />

        <Typography.Title level={5} style={{ fontWeight: 500 }}>
          Inactivas
        </Typography.Title>
        <div className="cards-integration">{renderInactiveIntegrations()}</div>
      </div>
      <DrawerIntegracion
        drawerIntegration={drawerIntegration}
        setDrawerIntegration={setDrawerIntegration}
      />
    </MainLayout>
  );
};

export default Integraciones;
