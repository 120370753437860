// excelUtils.js
import * as XLSX from "xlsx";
import dayjs from "dayjs";

/**
 * Exporta datos a un archivo Excel.
 *
 * @param {Array} data - Datos a exportar.
 * @param {string} fileName - Nombre del archivo a guardar.
 * @param {string} sheetName - Nombre de la hoja de cálculo.
 */

export const exportMPToXLS = (data, fileName, sheetName) => {
  try {
    // Obtener la fecha actual
    const execDate = dayjs().format("DD/MM/YYYY");

    // Transformar los datos en un formato plano con cabeceras personalizadas
    const flatData = data
      .map((item) => {
        // Expandir el objeto en varias filas si hay múltiples códigos
        return item.codigo.map((codigoItem) => ({
          "ID Medios Pago": item.idMediosPago,
          "Nombre Medios Pago": item.nombreMediosPago,
          "Tipo Medios Pago":
            item.tipoMediosPago === "1" ? "DÉBITO" : "CRÉDITO",
          "ID Medios Pago ERP Integración":
            codigoItem.idMediosPagoERPIntegracion,
          "Código ERP": codigoItem.codigoERP,
          "Descripción ERP": codigoItem.descripcionERP,
        }));
      })
      .flat(); // Aplanar el array de arrays

    //  Crear una hoja de cálculo con los datos principales
    const worksheet = XLSX.utils.json_to_sheet(flatData, {
      header: [
        "ID Medios Pago",
        "Nombre Medios Pago",
        "Tipo Medios Pago",
        "ID Medios Pago ERP Integración",
        "Código ERP",
        "Descripción ERP",
      ],
    });

    //Crear sheet y agregar la hoja de cálculo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    //Exportar el archivo Excel
    XLSX.writeFile(workbook, `${fileName}_${execDate}.xlsx`);
  } catch (error) {
    console.log(error);
  }
};
