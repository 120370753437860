import axios from "axios";

const GETListarPlanesPago = async (loggedUser, integration) => {
  try {
    const { tokenDatos, codEmpresa } = loggedUser;
    const { codigo } = integration;

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/Integraciones/ListarPlanesPagos?codEmpresa=${codEmpresa}&codIntegracion=${codigo}`,
      headers: {
        Authorization: `Bearer ${tokenDatos.accessToken}`,
      },
    };

    const response = await axios.request(config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};

export default GETListarPlanesPago;
