/* eslint-disable react-hooks/exhaustive-deps */
import {
  Drawer,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Tooltip,
  Tag,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import React, { useContext, useEffect, useState, useRef } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import PUTGuardarMediosPagoERP from "../../../helpers/integraciones/PUTGuardarMediosPagoERP";
import { useNotification } from "../../notification/OpenNotification";

const drawerStyles = {
  body: {
    padding: "0 16px 16px",
  },
};

const DrawerMediosPago = ({
  openDrawer,
  setOpenDrawer,
  selectedMedioPago,
  setSelectedMedioPago,
  onClose,
}) => {
  const [inputs, setInputs] = useState([]);
  const [markedForRemoval, setMarkedForRemoval] = useState([]);
  const [duplicateCodes, setDuplicateCodes] = useState([]);
  const { selectedIntegration, loggedUser } = useContext(GlobalContext);

  const openNotification = useNotification();

  // Referencias para cada input, para manipularlo, en este caso lo utilizo para darle foco cuando se crea
  const inputRefs = useRef([]);

  useEffect(() => {
    if (selectedMedioPago && selectedMedioPago.codigo?.length > 0) {
      const filteredCodes = selectedMedioPago.codigo.filter(
        (code) => code.codigoERP
      ); // Filtrar solo los códigos con códigoERP válido

      if (filteredCodes.length > 0) {
        const original = filteredCodes.map((code) => ({
          ...code,
          original: true,
        }));

        setInputs(original);
      } else {
        // En el caso de no tener codigos asociados, invoco addInput y agrega input por default
        addFirstInput();
      }
    }
  }, [selectedMedioPago]);

  const addFirstInput = () => {
    // Sugiere first input y trae el idMediosPagoERPintegracion default
    setInputs([
      ...inputs,
      {
        codigoERP: null,
        descripcionERP: null,
        idMediosPagoERPIntegracion:
          selectedMedioPago.codigo[0].idMediosPagoERPIntegracion,
      },
    ]);

    // Establecer foco en el último input
    setTimeout(() => {
      const lastIndex = inputs.length;
      if (inputRefs.current[lastIndex]) {
        inputRefs.current[lastIndex].focus();
      }
    }, 100); // Retardo para garantizar que el input esté en el DOM
  };

  const addInput = () => {
    setInputs([
      ...inputs,
      {
        codigoERP: null,
        descripcionERP: null,
        idMediosPagoERPIntegracion: 0, // Antes era 0
        // idMediosPagoERPIntegracion:
        //   selectedMedioPago.codigo[0].idMediosPagoERPIntegracion, // Antes era 0
      },
    ]);

    // Establecer foco en el último input
    setTimeout(() => {
      const lastIndex = inputs.length;
      if (inputRefs.current[lastIndex]) {
        inputRefs.current[lastIndex].focus();
      }
    }, 100); // Retardo para garantizar que el input esté en el DOM
  };

  const markInputForRemoval = (index) => {
    // Check if the input is marked as "original"
    if (inputs[index].original) {
      if (!markedForRemoval.includes(index)) {
        setMarkedForRemoval([...markedForRemoval, index]);
      } else {
        setMarkedForRemoval(markedForRemoval.filter((item) => item !== index));
      }
    } else {
      // If it is a recently added (volatile) input, remove it directly
      const updatedInputs = inputs.filter((_, i) => i !== index);
      setInputs(updatedInputs);
    }

    // Check for duplicates after removal
    const updatedInputs = inputs.filter((_, i) => i !== index);
    const codes = updatedInputs.map((input) => input.codigoERP);

    // Filter valid codes (non-null)
    const validCodes = codes.filter((code) => code !== null);

    // Find duplicates only among valid codes
    const duplicates = validCodes.filter(
      (code, index) => validCodes.indexOf(code) !== index
    );

    setDuplicateCodes(duplicates);
  };

  const handleInputChange = (index, field, value) => {
    const newInputs = inputs.map((input, i) => {
      if (i === index) {
        return { ...input, [field]: value.toUpperCase() };
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleBlur = () => {
    // Control de repetición de codigos, tanto en los del medio de pago, como contra los demás
    const codes = inputs.map((input) => input.codigoERP);

    // Filtrar los códigos válidos (no nulos)
    const validCodes = codes.filter((code) => code !== null || code !== "");

    // Encontrar duplicados dentro de los inputs actuales
    const duplicatesInValidCodes = validCodes.filter(
      (code, index) => validCodes.indexOf(code) !== index
    );

    const allDuplicates = [...new Set([...duplicatesInValidCodes])];

    setDuplicateCodes(allDuplicates);
  };

  const handleSave = async () => {
    // Filtrar los inputs para eliminar aquellos con código null
    const filteredInputs = inputs
      .filter((input) => input.codigoERP !== null)
      .map((input) => {
        const { original, ...rest } = input; // Eliminar la llave "original" si existe
        return rest;
      });

    // Agregar la llave action a cada elemento basado en add/update o delete
    const finalCodes = filteredInputs.map((input, index) => ({
      ...input,
      action: markedForRemoval.includes(index) ? 0 : 1,
    }));

    const payload = {
      IdMediosPagoIntegracion: selectedMedioPago.idMediosPago,
      codigosMPERP: finalCodes,
      codigoIntegracion: selectedIntegration.codigo,
    };

    try {
      const response = await PUTGuardarMediosPagoERP(loggedUser, payload);

      // Verificar si la respuesta contiene el mensaje específico de error al borrar
      if (
        response.data.result.some((item) => item.msg.includes("Sin Borrar"))
      ) {
        return openNotification({
          type: "warning",
          message: "No fue posible eliminar los códigos",
          description: response.data.result.find((item) =>
            item.msg.includes("Sin Borrar")
          ).msg,
          duration: 1.5,
        });
      } else if (
        response.data.result.some((item) => item.msg.includes("Existe código"))
      ) {
        return openNotification({
          type: "warning",
          message: "No fue posible insertar los códigos",
          description: response.data.result.find((item) =>
            item.msg.includes("Existe código")
          ).msg,
          duration: 1.5,
        });
      }
      if (response.status === 200) {
        const updatedMedioPago = {
          ...selectedMedioPago,
          codigo: response.data.codigosMPERP, // Tomo los insertados para actualizar el registro actual en el cliente
        };

        setSelectedMedioPago(null);
        setInputs([]);
        setMarkedForRemoval([]);
        setDuplicateCodes([]);
        onClose(updatedMedioPago);

        openNotification({
          type: "success",
          message: "Códigos guardados correctamente.",
          description: (
            <div style={{ display: "flex", gap: "10px" }}>
              {payload.codigosMPERP.map((item) => {
                if (item.action === 1) {
                  return (
                    <Tag
                      bordered={false}
                      key={item.codigoERP}
                      style={{
                        marginRight: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      {item.codigoERP}
                      {item.descripcionERP && ` - ${item.descripcionERP}`}
                    </Tag>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          ),
          duration: 1.5,
        });

        setInputs([]); // Vacío la memoria volátil de los inputs
      } else {
        openNotification({
          type: "error",
          message: "Se produjo un error",
          duration: 1.5,
        });
      }
    } catch (error) {
      openNotification({
        type: "error",
        message: "Se produjo un error",
        description: error.message,
        duration: 1.5,
      });
    }
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    setSelectedMedioPago(null);
    setInputs([]);
    setMarkedForRemoval([]);
    setDuplicateCodes([]);
  };

  const isSaveDisable = () => {
    // Verificar si al menos un elemento en inputs tiene codigoERP definido y !== null
    const atLeastOneCodeDefined = inputs.some(
      (input) => input.codigoERP !== null
    );

    // Si no hay elementos en inputs, ninguno tiene codigoERP definido y !== null, o hay duplicados, retornar true para deshabilitar
    if (
      inputs.length === 0 ||
      !atLeastOneCodeDefined ||
      duplicateCodes.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSave();
      }
    };

    if (openDrawer) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openDrawer, handleSave]);

  return (
    <Drawer
      styles={drawerStyles}
      destroyOnClose
      maskClosable={false}
      title={selectedMedioPago?.nombreMediosPago}
      open={openDrawer}
      placement="right"
      onClose={handleCancel}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleCancel} style={{ marginRight: 8 }}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={isSaveDisable()}
          >
            Guardar
          </Button>
        </div>
      }
    >
      <Row
        gutter={24}
        style={{
          marginBottom: "12px",
          backgroundColor: "white",
          position: "sticky",
          top: 0,
          zIndex: 9999,
          padding: "12px",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Col span={10}>
          <Typography.Text strong style={{ fontSize: "12px" }}>
            Código
          </Typography.Text>
        </Col>
        <Col span={10}>
          <Typography.Text strong style={{ fontSize: "12px" }}>
            Descripción
          </Typography.Text>
        </Col>
        <Col span={4}></Col>
      </Row>
      {inputs.map((input, index) => (
        <Row key={index} gutter={16} style={{ marginBottom: 16 }}>
          <Col span={10}>
            <Input
              ref={(el) => (inputRefs.current[index] = el)}
              value={input.codigoERP}
              placeholder="Código"
              onChange={(e) =>
                handleInputChange(index, "codigoERP", e.target.value)
              }
              onBlur={handleBlur}
              style={{
                borderColor: duplicateCodes.includes(input.codigoERP)
                  ? "red"
                  : "",
              }}
              disabled={markedForRemoval.includes(index)}
            />
          </Col>
          <Col span={10}>
            <Input
              value={input.descripcionERP}
              placeholder="Descripción"
              onChange={(e) =>
                handleInputChange(index, "descripcionERP", e.target.value)
              }
              disabled={markedForRemoval.includes(index)}
            />
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {markedForRemoval.includes(index) ? (
              <Tooltip title={"Recuperar código de integración"}>
                <UndoOutlined
                  onClick={() => markInputForRemoval(index)}
                  style={{ color: "green" }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Eliminar código de integración"}>
                <MinusCircleOutlined
                  onClick={() => markInputForRemoval(index)}
                  style={{ color: "red" }}
                />
              </Tooltip>
            )}
          </Col>
        </Row>
      ))}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="dashed"
          size="small"
          icon={<PlusOutlined style={{ width: "15px" }} />}
          onClick={addInput}
        />
      </div>
    </Drawer>
  );
};

export default DrawerMediosPago;
