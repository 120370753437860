// PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element, loggedUser }) => {
  return Object.keys(loggedUser).length > 0 ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
