import axios from "axios";

const PUTGuardarMediosPagoERP = async (loggedUser, payload) => {
  try {
    const { tokenDatos } = loggedUser;

    let data = JSON.stringify(payload);

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/Integraciones/GuardarMediosPagoERP`,
      headers: {
        Authorization: `Bearer ${tokenDatos.accessToken}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios.request(config);
    return response;
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};

export default PUTGuardarMediosPagoERP;
