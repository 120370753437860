/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Avatar, Image, Layout, Menu, Typography } from "antd";
import {
  ApiOutlined,
  CreditCardOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import ProfileSettings from "../login/profileSettings/ProfileSettings";
import genesiologo from "../../assets/genesio.jpeg";
import innercorlogo from "../../assets/innercor-logo.png";
import { getDeployEnvironment } from "../../utils/getDeployEnvironment";
const { Header, Content, Footer, Sider } = Layout;

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(80); // Valor por defecto del menú plegado
  const [sideBarMenuKey, setSideBarMenuKey] = useState();
  const [pathnameHeader, setPathnameHeader] = useState("");

  const items = [
    {
      key: "1",
      icon: <ApiOutlined />,
      label: "Integraciones",
      path: "/integraciones",
    },
    {
      key: "2",
      icon: <CreditCardOutlined />,
      label: "Medios de pago",
      path: "/mediospago",
    },
    {
      key: "3",
      icon: <ProfileOutlined />,
      label: "Planes de pago",
      path: "/planespago",
    },
  ];

  useEffect(() => {
    // seguimiento de ruta para menu item active
    const pathname = location.pathname;

    determineCurrentPathForHeader(pathname);
    const foundItem = items.find((item) => item.path === pathname);
    if (foundItem) {
      setSideBarMenuKey(foundItem.key);
    }
  }, [location.pathname, items]);

  useEffect(() => {
    // comienza estando plegado el menú
    setCollapsed(true);

    const handleResize = () => {
      // Obtener el ancho de la pantalla
      const screenWidth = window.innerWidth;

      // Establecer collapsedWidth basado en el tamaño de la pantalla
      if (screenWidth <= 768) {
        // Puedes ajustar este valor según tus necesidades de diseño responsive
        setCollapsedWidth(0); // Tamaño 0 para móviles
      } else {
        setCollapsedWidth(80); // Tamaño 80 para otros tamaños de pantalla
      }
    };

    // Ejecutar handleResize al cargar y al redimensionar la ventana
    handleResize();
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const determineCurrentPathForHeader = (pathname) => {
    switch (true) {
      case pathname === "/integraciones":
        setPathnameHeader("Integraciones");
        break;
      case pathname === "/mediospago":
        setPathnameHeader("Medios de pago");
        break;
      case pathname === "/planespago":
        setPathnameHeader("Planes de pago");
        break;
      default:
        break;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth={collapsedWidth}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          className="sider-image-wrapper"
          onClick={() => navigate("/integraciones")}
        >
          <Image width={70} src={innercorlogo} preview={false} />
        </div>
        <Menu
          items={items}
          theme="dark"
          selectedKeys={[sideBarMenuKey]}
          mode="inline"
          onSelect={({ key }) => {
            const selectedItem = items.find((item) => item.key === key);
            if (selectedItem) {
              setSideBarMenuKey(selectedItem.key);
              navigate(selectedItem.path);
            }
          }}
        ></Menu>
      </Sider>
      <Layout>
        <Header className="header-wrapper">
          <Typography.Text style={{ fontWeight: 500 }}>
            {pathnameHeader}
          </Typography.Text>
          <ProfileSettings>
            <Avatar
              src={innercorlogo}
              size={32}
              icon={<UserOutlined />}
              style={{ cursor: "pointer" }}
            />
            {/* <Avatar
              src={genesiologo}
              size={32}
              icon={<UserOutlined />}
              style={{ cursor: "pointer" }}
            /> */}
          </ProfileSettings>
        </Header>
        <Content style={{ margin: "12px 12px" }}>
          <div className="body-wrapper">{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
            © {new Date().getFullYear()} Innercor Soluciones ERP{" "}
            {getDeployEnvironment()}
          </Typography.Text>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
