// excelUtils.js
import * as XLSX from "xlsx";
import dayjs from "dayjs";

/**
 * Exporta datos de códigos de medios de pago a un archivo Excel.
 *
 * @param {Array} data - Datos a exportar.
 * @param {string} fileName - Nombre del archivo a guardar.
 * @param {string} sheetName - Nombre de la hoja de cálculo.
 */
export const exportPPToXLS = (data, fileName, sheetName) => {
  try {
    // Obtener la fecha actual
    const execDate = dayjs().format("DD/MM/YYYY");

    // Transformar los datos en un formato plano con cabeceras personalizadas
    const flatData = data
      .map((item) => {
        // Expandir los códigos de medios de pago en varias filas si hay múltiples códigos
        return item.codigoMPERP.map((codigoMPERPItem) => {
          // Expandir los códigos PPERP en varias filas si hay múltiples códigos
          return codigoMPERPItem.codigoPPERP.map((codigoPPERPItem) => ({
            "ID Medios Pago": item.idMediosPago,
            "Nombre Medios Pago": item.nombreMediosPago,
            "Tipo Medios Pago":
              item.tipoMediosPago === "1" ? "DÉBITO" : "CRÉDITO",
            "ID Medios Pago ERP Integración":
              codigoMPERPItem.idMediosPagoERPIntegracion,
            "Código MP ERP": codigoMPERPItem.codigoMPERP,
            "Descripción MP ERP": codigoMPERPItem.descripcionERP,
            "ID Planes Pago Integración":
              codigoPPERPItem.idPlanesPagoIntegracion,
            "ID Planes Pago ERP Integración":
              codigoPPERPItem.idPlanesPagoERPIntegracion,
            "Código PP ERP": codigoPPERPItem.codigoPPERP,
            "Descripción PP ERP": codigoPPERPItem.descripcionERP,
            Coeficiente: codigoPPERPItem.coeficiente,
          }));
        });
      })
      .flat(2); // Aplanar el array de arrays

    // Crear una hoja de cálculo con los datos principales
    const worksheet = XLSX.utils.json_to_sheet(flatData, {
      header: [
        "ID Medios Pago",
        "Nombre Medios Pago",
        "Tipo Medios Pago",
        "ID Medios Pago ERP Integración",
        "Código MP ERP",
        "Descripción MP ERP",
        "ID Planes Pago Integración",
        "ID Planes Pago ERP Integración",
        "Código PP ERP",
        "Descripción PP ERP",
        "Coeficiente",
      ],
    });

    // Crear un sheet y agregar la hoja de cálculo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    // Exportar el archivo Excel
    XLSX.writeFile(workbook, `${fileName}_${execDate}.xlsx`);
  } catch (error) {
    console.log(error);
  }
};
