import React, { useEffect } from "react";
import { Form, Input } from "antd";

const CredentialsForm = ({ integrationData }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // Cargar los datos en el formulario cuando integrationData cambie
    form.setFieldsValue({
      APIKey: integrationData?.apiKey || "",
      APISecret: integrationData?.apiSecret || "",
    });
  }, [form, integrationData]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      name="credentialsForm"
    >
      <Form.Item
        label={<span className="form-label">API Key</span>}
        name="APIKey"
      >
        <Input readOnly />
      </Form.Item>

      <Form.Item
        label={<span className="form-label">API Secret</span>}
        name="APISecret"
      >
        <Input readOnly />
      </Form.Item>
    </Form>
  );
};

export default CredentialsForm;
