import dayjs from "dayjs";

// Control de expiración del token frente a una sesión ya iniciada

const verifyAccessToken = ({ tokenDatos }) => {
  if (tokenDatos && tokenDatos.expiredAccessToken) {
    const givenDate = tokenDatos.expiredAccessToken;
    const parsedExpiredDate = dayjs(givenDate);

    const currentDate = dayjs();

    const isExpired = currentDate.isAfter(parsedExpiredDate);

    return isExpired;
  } else {
    return true;
  }
};

export default verifyAccessToken;
