import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET;

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const saveDataInStorage = async (key, value) => {
  try {
    const encryptedValue = encryptData(value);
    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error(error);
  }
};

export const getDataInStorage = async (key) => {
  try {
    const encryptedData = localStorage.getItem(key);
    if (encryptedData) {
      const decryptedData = decryptData(encryptedData);
      return decryptedData;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const removeDataInStorage = async (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(error);
  }
};
